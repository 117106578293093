import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import Layout from 'components/layout';
import Hero from 'components/hero';
import Container from '@material-ui/core/Container';
import {
  TitleText,
  FullImage,
  Statistics,
  ImageGrid,
  LatestNews,
  QuoteImageBlock,
  ImageTextOverlay,
} from 'components/slices';
import { Helmet } from 'react-helmet';

function Index(props) {
  const { data } = props;
  const doc = data.prismicHomepage;
  if (!doc) return null;
  return (
    <Layout>
      {doc.data.metadata && doc.data.metadata[0] && (
        <Helmet>
          {doc.data.metadata[0].title && doc.data.metadata[0].title[0] && (
            <title>{doc.data.metadata[0].title[0].text}</title>
          )}
          {doc.data.metadata[0].description &&
            doc.data.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.data.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.data.metadata[0].keywords &&
            doc.data.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.data.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <Hero data={doc.data} />
      {doc.data.body && (
        <Container maxWidth="xl">
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'title___text':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <TitleText data={item.primary} />
                    </div>
                  </div>
                );
              case 'full_image_block':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <FullImage data={item.primary} />
                    </div>
                  </div>
                );
              case 'statistics':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <Statistics data={item.items} />
                    </div>
                  </div>
                );
              case 'image_grid':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <ImageGrid data={item.items} />
                    </div>
                  </div>
                );
              case 'latest_news':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <LatestNews data={item.primary} />
                    </div>
                  </div>
                );
              case 'quote_image_block':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <QuoteImageBlock data={item.primary} />
                    </div>
                  </div>
                );
              case 'image_with_text_overlay':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <ImageTextOverlay data={item.primary} />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </Container>
      )}
    </Layout>
  );
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Index);

export const query = graphql`
  query HomePageQuery {
    prismicHomepage {
      data {
        metadata {
          description {
            text
          }
          keywords {
            text
          }
          title {
            text
          }
        }
        banner_image {
          url
          alt
        }
        banner_title {
          html
          raw
          text
        }
        page_title {
          html
          raw
          text
        }
        body {
          ... on PrismicHomepageBodyQuoteImageBlock {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              quote {
                html
                raw
                text
              }
              job_title {
                html
                raw
                text
              }
              image {
                url
                alt
              }
              brand {
                alt
                url
              }
              author {
                text
                raw
                html
              }
            }
          }
          ... on PrismicHomepageBodyImageWithTextOverlay {
            slice_type
            primary {
              content {
                html
                raw
                text
              }
              image {
                url
                alt
              }
              link {
                url
                uid
                type
                id
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyTitleText {
            id
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyFullImageBlock {
            id
            slice_type
            primary {
              title {
                text
                raw
                html
              }
              link {
                uid
              }
              image {
                url
                alt
              }
              caption {
                text
                raw
                html
              }
            }
          }
          ... on PrismicHomepageBodyStatistics {
            slice_type
            items {
              title {
                html
                raw
                text
              }
              caption {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyImageGrid {
            slice_type
            items {
              anchor_element
              title {
                text
                raw
                html
              }
              link {
                url
                uid
                type
              }
              image {
                url
                alt
              }
              caption {
                text
                raw
                html
              }
            }
          }
          ... on PrismicHomepageBodyLatestNews {
            slice_type
            primary {
              number_of_articles
            }
          }
        }
      }
    }
  }
`;
